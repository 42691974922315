import { Route, Routes } from 'react-router';
import './App.scss';
import { Home } from './routes/home/home.component';
import { Navigation } from './routes/navigation/navigation.component';
import { Reports } from './routes/reports/reports.component';

function App() {
	return (
		<Routes>
			<Route path='/' element={<Navigation />}>
				<Route index element={<Home />} />
				<Route path='reports/*' element={<Reports />}></Route>
			</Route>
		</Routes>
	);
}

export default App;
