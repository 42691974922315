import { ChangeEvent, FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { NetReport } from "../../store/report/report.types";

export function Home() {
	const [fileFields, setFileFields] = useState<File>()
	const [fileName, setFIleName] = useState("")
	const navigate = useNavigate()

	const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setFileFields(event.target.files?.[0]!!)
	}

	const onFileNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setFIleName(event.target.value)
	}

	const onFormSent = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		console.log(fileFields?.name)
		const fileReader = new FileReader()
		fileReader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
			const rawReport: string = readerEvent.target?.result as string ?? ""
			var parsedReport: NetReport = JSON.parse(rawReport)
			parsedReport.name = fileName
			parsedReport.uuid = uuidv4()
			const existingReports = localStorage.getItem("reports")
			if (existingReports) {
				//There is existing reports 
				const reports: NetReport[] = JSON.parse(localStorage.getItem("reports")!!)
				reports.push(parsedReport)
				localStorage.setItem("reports", JSON.stringify(reports))
			} else {
				//No reports - Init required.
				const emptyArray = []
				emptyArray.push(parsedReport)
				localStorage.setItem("reports", JSON.stringify(emptyArray))
			}
			navigate("/reports")
		}
		fileReader.readAsText(fileFields!!, 'UTF-8')
	}

	return (
		<Container>
			<Form onSubmit={onFormSent}>
				<Form.Group controlId="formFileLg" className="mb-3">
					<Form.Label>Report name</Form.Label>
					<Form.Control
						required
						type="text"
						id="report_name"
						onChange={onFileNameChanged}
					/>
					<Form.Label>Upload network report:</Form.Label>
					<Form.Control required type="file" size="lg" onChange={onFileChanged} />
				</Form.Group>
				<Button variant="primary" type="submit">
					Submit
				</Button>
			</Form>
		</Container>
	)
}