import { useState } from "react"
import { Table } from "react-bootstrap"
import { ReportItem } from "../../components/report-item/report-item.component"
import { NetReport } from "../../store/report/report.types"

export function ReportsPreview() {
	//const [reports, setReports] = useState<NetReport[]>()
	const [reports, setReports] = useState<NetReport[]>(JSON.parse(localStorage.getItem("reports")!!))

	if (reports) {
		return (
			<Table striped bordered hover size="lg">
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Date</th>
						<th>Result */3</th>
						<th>X</th>
					</tr>
				</thead>
				<tbody>
					{reports.map((report, index) => {
						return (
							// Fixme: Performance issue here. 
							<ReportItem uuid={report.uuid} id={index} report={report} onDelete={() => {
								const reports: NetReport[] = JSON.parse(localStorage.getItem("reports")!!)
								const updatedReportList = reports.filter((item) => item.uuid !== report.uuid)
								localStorage.setItem("reports", JSON.stringify(updatedReportList))
								setReports(updatedReportList)
							}} />
						)
					})}
				</tbody>

			</Table>
		)
	} else {
		return (
			<div>No reports yet.</div>
		)
	}
}