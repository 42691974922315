import { Route, Routes } from "react-router-dom";
import { ReportsPreview } from "../reports-preview/reports-preview.component";
import { ReportView } from "../report-view/report-view.component";

export function Reports() {
	return (
		<Routes>
			<Route index element={<ReportsPreview />} />
			<Route path=":report" element={<ReportView />} />
		</Routes>
	)
}