import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from "./root-reducer";

import { applyMiddleware, compose, createStore } from "redux";

import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import { rootSaga } from "./root-saga";

export type RootState = ReturnType<typeof rootReducer>

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__?: typeof compose
	}
}

type ExtendedPersistConfig = PersistConfig<RootState> & {
	whitelist: (keyof RootState)[]
}

const persistConfig: ExtendedPersistConfig = {
	key: 'root',
	storage,
	whitelist: ['tbd'] // Fixme: Properly whitelist the 
}

const sagaMiddleWare = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middleWares = [
// 	process.env.NODE_ENV !== 'production',
// 	sagaMiddleWare
// ].filter((middleware): middleware is Middleware => Boolean(middleware));

const composeEnhancers =
	(process.env.NODE_ENV !== 'production' &&
		window &&
		window.__REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__) || compose;

const composedEnhancers = composeEnhancers(applyMiddleware(sagaMiddleWare))


export const store = createStore(persistedReducer, undefined, composedEnhancers)

sagaMiddleWare.run(rootSaga);

export const persistor = persistStore(store)