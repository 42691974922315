import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import { NetReport } from "../../store/report/report.types";

export function ReportView() {
	const { report } = useParams();
	const reportData: NetReport = JSON.parse(localStorage.getItem("reports")!!)[Number(report)]

	return (
		<div>
			<br />
			<p>Report #{report} / {reportData.date}</p>
			<h3>1. Network Information</h3>
			<Table bordered >
				<tbody>
					IPs:
					<tr>
						{reportData.networkInfo.address.split(' ').map((item, index) => {
							return (
								<td>{index === 0 ? reportData.networkInfo.connectionType : <a target="_blank" href={"https://ipinfo.io/" + item} rel="noreferrer" >{item}</a>}</td>
							)
						})}
					</tr>
					<tr>
						<td>
							DNS:
						</td>
						<td>
							{reportData.networkInfo.dnsServers}
						</td>
					</tr>
					<tr>
						<td>
							Proxy:
						</td>
						<td>
							{reportData.networkInfo.httpProxy}
						</td>
					</tr>
				</tbody>
			</Table>
			<br />
			<h3>2. Ice Test</h3>
			<Table bordered>
				<thead>
					<tr>
						<th>
							Address
						</th>
						<th>
							Port
						</th>
						<th>
							Protocol
						</th>
						<th>
							Relay Protocol
						</th>
						<th>
							Url
						</th>
					</tr>
				</thead>
				<tbody>
					{
						reportData.iceResult.data?.map((iceResult) => {
							return (
								<tr>
									<td>{iceResult.address}</td>
									<td>{iceResult.port}</td>
									<td>{iceResult.protocol}</td>
									<td>{iceResult.relayProtocol}</td>
									<td>{iceResult.url}</td>
								</tr>
							)
						})
					}
					Test Result: {reportData.iceResult.result}
				</tbody>
			</Table>
			<br />
			<h3>3. Vonage Pre-Call test</h3>
			<img alt="error when generating precall" src={`data:image/jpeg;base64,${reportData.precallResult}`} />
			<h3>4. Endpoint test</h3>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Endpoint</th>
						<th>Open?</th>
					</tr>
				</thead>
				<tbody>
					{reportData.tcpResult?.map((testResult) => {
						return (
							<tr>
								<td>{testResult.endpoint}</td>
								<td>{String(testResult.isSuccess)}</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</div>
	)
}