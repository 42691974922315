import { Container, Nav, Navbar } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router";


export function Navigation() {
	const navigate = useNavigate()
	const onHomeNavLinkClicked = () => navigate("/")
	const onAboutNavLinkClicked = () => navigate("/reports")

	return (
		<Container >
			<Navbar expand="lg" className="bg-body-tertiary">
				<Container>
					<Navbar.Brand onClick={onHomeNavLinkClicked}>Net-Insight</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link onClick={onHomeNavLinkClicked}>Home</Nav.Link>
							<Nav.Link onClick={onAboutNavLinkClicked}>Reports</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Outlet />
		</Container>
	)
}