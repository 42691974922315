import { FC } from "react";
import { useNavigate } from "react-router";
import { NetReport } from "../../store/report/report.types";

export type ReportItemProps = {
	id: number;
	uuid: string;
	report: NetReport;
	onDelete: (id: string) => void
}

export const ReportItem: FC<ReportItemProps> = ({ id, uuid, report, onDelete }) => {
	const navigate = useNavigate()
	const onItemClicked = () => navigate(id.toString())
	const onRemoveReportClicked = () => {
		onDelete(uuid)
	}
	return (
		<tr>
			<th onClick={onItemClicked}>{id + 1}</th>
			<th onClick={onItemClicked}>{report.name}</th>
			<th onClick={onItemClicked}>{report.date}</th>
			<th onClick={onItemClicked}>{report.networkInfo.address}</th>
			<th onClick={onRemoveReportClicked}><p>X</p></th>
		</tr>
	)
}